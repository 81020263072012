import RestrictedTags from "./retrictedTags";

const ContentTeaser = ({gatedContent}) => {
    const loggedIn = gatedContent?.loggedIn;
    const restrictedDocs = gatedContent?.restrictedDocs;
    const restrictedTags = gatedContent?.restrictedTags;

    const chatbotLogin = () => {
        window.parent.postMessage("chatbotLogin", '*');
    }

    if(!restrictedDocs){
        return (<></>)
    }
    
    if(!loggedIn){
        return (
            <span>{`\n\n`}It looks like I found some information that you don't have access to. Try <button className="button-link" id="js-koisbot-login" onClick={chatbotLogin}>logging in.</button></span>
        )
    } else if (restrictedTags) {
        return(
            <RestrictedTags restrictedTags={restrictedTags}/>
        )
    }

}

export default ContentTeaser;