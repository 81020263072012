import { configureStore } from "@reduxjs/toolkit";
//import exampleStateReducer from './exampleStateSlice';
import chatHistoryReducer from './chatHistory/chatHistorySlice';
import { chatbotApi } from "../api/chatbot";

const store = configureStore({ 
    reducer: {
        chatHistory: chatHistoryReducer,
        [chatbotApi.reducerPath]: chatbotApi.reducer,
    },
   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([chatbotApi.middleware])
});

export default store;