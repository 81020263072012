const RestrictedTags = ({restrictedTags}) => {
    console.log('restricted tags', restrictedTags)

    return (
        <>
            {restrictedTags?.course?.length > 0 ? (
                <span>{`\n\nIt looks there is more information available once you’ve taken: `}
                    {restrictedTags.course.map((c, i) => {
                        if(!c.link) {
                            return <span key={i}>{c.title}{i === restrictedTags.course.length - 1 ? '' : ', '}</span>
                        }
                        else {
                            return <span key={i}><a href={c.link} target='_blank' rel="noreferrer">{c.title}</a>{i === restrictedTags.course.length - 1 ? '' : ', '}</span>
                        }
                    })}
                </span>
            ) : null}

            {restrictedTags?.category?.length > 0 ? (
                <span>{`\n\nI ${restrictedTags.category.length > 0 ? 'also ' : ''}have more information available to `}
                    {restrictedTags.category.map((c, i) => {
                        return <span key={i}>{(i === restrictedTags.category.length - 1 && restrictedTags.category.length > 1) ? 'and ' : ''}{c.title}{i === restrictedTags.category.length - 1 ? '.' : ', '}</span>
                    })}
                </span>
            ) : null}
        </>
    )
}

export default RestrictedTags;