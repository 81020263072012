import BotMessageIcon from '../svgs/bot-message-icon';
import LoadingIcon from '../svgs/loading-icon';

const BotFetching = () => {

    return(
    <div className={"bot-message-wrapper"}>
        <BotMessageIcon />
        <div className={"bot-message"}>
            <div className={"bot-message__name"}>John</div>
            <div className={"bot-message__fetching"}>
                <LoadingIcon/>
            </div>
        </div>
    </div>
    )
}

export default BotFetching;