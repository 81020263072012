//import redux state
import { useSelector, useDispatch } from 'react-redux'
import { prependChatHistory } from '../../redux/chatHistory/chatHistorySlice'
// import api
import { useLazyLoadChatHistoryQuery, useLazyCheckForChatHistoryQuery } from '../../api/chatbot';
import { useEffect } from 'react';

const LoadMoreBtn = ({user, sendMessageIsFetching, hasHistoryLoaded, setHasHistoryLoaded }) => {
    // get the chat history from the redux store
    const chatHistory = useSelector((state) => state.chatHistory.value)
    const dispatch = useDispatch()

    // get the user sub from the auth0 user object
    const userSub = user?.sub? user.sub : null;

    const [ loadChatHistory, { isFetching: loadChatHistoryIsFetching, currentData: loadChatHistoryCurrentData } ] = useLazyLoadChatHistoryQuery();
    const [ checkForChatHistory, { currentData: checkForChatHistoryCurrentData } ] = useLazyCheckForChatHistoryQuery();

    // click handler for the "Load More History" button
    const loadChatHistoryClickHandler = () => {
        // load the next 10 messages
        const limit = 10;
        // get the offset for the next set of messages - offset makes sure we don't load in the same messages again
        const offset = (chatHistory.length - 1)/2;
        loadChatHistory({identifier: userSub, limit: limit, offset: offset})
    }

    // check if user has chat history left to load
    useEffect(() => {
        // if they are logged in and we are not currently fetching chat history
        if(userSub && !loadChatHistoryIsFetching){
            // offset so we dont check for messages we already have
            const offset = (chatHistory.length - 1)/2;
            checkForChatHistory({identifier: userSub, offset: offset}, false);
        }
        // this effect runs when a user is logged in, when we are not fetching chat history, and when the chat history has loaded
    }, [userSub, loadChatHistoryIsFetching, chatHistory] )

    useEffect(() => {
        // when we have chat history data add it to the chat history state in the parent component
        if(loadChatHistoryCurrentData && loadChatHistoryCurrentData.chatHistory?.length > 0){
            if(!hasHistoryLoaded){
                // flag that we have history loaded to display the seperator element on the front end (in parent component)
                setHasHistoryLoaded(true);
            }
            dispatch(prependChatHistory(loadChatHistoryCurrentData.chatHistory));
        }
    }
    , [loadChatHistoryCurrentData, dispatch])

    return (
        <>
            {userSub && checkForChatHistoryCurrentData && checkForChatHistoryCurrentData.chatHistory?.length > 0 && 
                <div className="load-more-container"><button onClick={loadChatHistoryClickHandler} disabled={sendMessageIsFetching || loadChatHistoryIsFetching} className="load-more-button button-link">Load More History</button></div>
            }
        </>
    )
}

export default LoadMoreBtn;