import { createSlice } from '@reduxjs/toolkit'

// This state handles which course you are currently looking at on the page (eg. Functional Occlucion and Treatment Planning Course 165).
// It's updated on swiping the course swiper.
export const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState: {
    value: [{
        source: "bot", 
        message: "Hello! How can I help you today?",
        welcomeMessage: true,
        key: 0,
    }]},
  reducers: {
    // append the user message to the chat history state
    appendUserMessage: (state, action,) => {
      // Remove the scrollOffset property from the array
      state.value.forEach(element => {
        if(element.scrollOffset){
          delete element.scrollOffset;
        }
      });
        state.value.push({source: "user", message: action.payload, key: state.value.length});
    },
    
    // append the bot response to the chat history state
    appendBotResponse: (state, action) => {
      // Remove the scrollOffset property from the array
      state.value.forEach(element => {
        if(element.scrollOffset){
          delete element.scrollOffset;
        }
      });
        // build out the bot response object
        const botRespObj = {
            source: "bot",
            message: action.payload.answer,
            chatHistoryId: action.payload.chatHistoryId,
            gatedContent: action.payload.gatedContent,
            citations: action.payload.citations,
            key: state.value.length,
        }

        state.value.push(botRespObj);
    },

    // prepend chat history to the chat history state when the user clicks the "Load More History" button
    prependChatHistory: (state, action) => {
      let chatHistory = [...action.payload];

      // Remove the scrollOffset property from the array
      state.value.forEach(element => {
        if(element.scrollOffset){
          delete element.scrollOffset;
        }
      });

      // if we dont have any messages in chat history we dont need this
      if(state.value.length > 1){
        // Access the last object
        let lastMessage = {...state.value[0]};
        // Add a new property to the last object
        lastMessage.scrollOffset = true;
        state.value.splice(0, 1, lastMessage);
      }

      // keep track of messages so we have a key for mapping on frontend
      let keyCounter = state.value.length;
      // loop through the chat history and prepend the messages to the chat history state
      chatHistory.reverse().forEach(element => {
        // build bot message object
        const botRespObj = {
          source: "bot",
          message: element.Answer,
          chatHistoryId: element.Id,
          key: keyCounter,
        }
        keyCounter++;
        state.value.unshift(botRespObj);
        // build user questions object
        const userQuestionObj = {
          source: "user",
          message: element.Question,
          key: keyCounter,
        }
        keyCounter++;
        state.value.unshift(userQuestionObj);
      });
  }
  },
})

// Action creators are generated for each case reducer function
export const { appendUserMessage, appendBotResponse, prependChatHistory } = chatHistorySlice.actions

export default chatHistorySlice.reducer