const LoadingIcon = () => {
    return (
        <svg className="dots" xmlns="http://www.w3.org/2000/svg" width="40" height="10" viewBox="0 0 40 10" fill="none">
            <circle cx="5" cy="5" r="5" fill="#C3CBCD"/>
            <circle cx="20" cy="5" r="5" fill="#C3CBCD"/>
            <circle cx="35" cy="5" r="5" fill="#C3CBCD"/>
        </svg>
    )
}

export default LoadingIcon;