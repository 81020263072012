import '../App.css';
import ChatCntr from './chat/chatCntr';
import CloseIcon from './svgs/close-icon';

const Container = () => {

const closeWindow = () => {
    window.parent.postMessage("closeIframe", '*');
}

  return (
    <>
        <header className="koisbot-header">
            <div className="top-bar">
                <h1 className="top-bar__header">Kois Center Collective Intelligence</h1>
                <button id="js-close-koisbot" className="top-bar__close" onClick={closeWindow}>
                    <CloseIcon/>
                </button>
            </div>
            
        </header>
        <ChatCntr isAdmin={false}/>
    </>
  );
}

export default Container;