import {useState} from 'react';
import SendIcon from '../svgs/send-icon';

const InputBar = ({handleInput, sendMessageIsFetching}) => {
    const [inputValue, setInputValue] = useState('');

    const clickHandler = (e) => {
        e.preventDefault();
        handleInput(inputValue);
        setInputValue('');
    }
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !disabledCheck()) {
            event.preventDefault();
            handleInput(inputValue);
            setInputValue('');
        }
      };

    const disabledCheck = () => {
        if(sendMessageIsFetching || inputValue.trim() === '') {
            return true;
        }
        return false
    }

    return (
        <form className="input-bar" onSubmit={clickHandler}>
            <textarea id="chat-input" value={inputValue} onChange={e => setInputValue(e.target.value)} type="text" placeholder="Type your question..." data-lpignore="true" autocomplete="off" onKeyDown={handleKeyDown}/>
            <button type={'submit'} disabled={disabledCheck()} id="send-button">
                <SendIcon/>
            </button>
        </form>
    );
}

export default InputBar;