import {useState, useRef, useEffect, useCallback} from 'react';
import InputBar from './inputBar';
import Citation from './citation';
import ContentTeaser from './contentTeaser';
import LoadMoreBtn from './loadMoreBtn';
import BotFetching from './botFetching'; 
import BotMessageIcon from '../svgs/bot-message-icon';
import FlagIcon from '../svgs/flag-icon';
import AlertIcon from '../svgs/alert-icon';
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';

//import redux state
import { useSelector, useDispatch } from 'react-redux'
import { appendUserMessage, appendBotResponse } from '../../redux/chatHistory/chatHistorySlice'

// import api
import { useLazySendMessageQuery, useLazyFlagBadResponseQuery } from '../../api/chatbot';

const ChatCntr = ({isAdmin}) => {
    const queryParameters = new URLSearchParams(window.location.search)
    const error = queryParameters.get("error")
    const [accessToken, setAccessToken] = useState(null);
    const [flaggedResponseIndexes, setFlaggedResponseIndexes] = useState([]); // array of indexes of flagged responses
    const [hasHistoryLoaded, setHasHistoryLoaded] = useState(false); // flag to indicate if chat history has been loaded in
    const tokenId = useRef(Cookies.get('tokenId') ? Cookies.get('tokenId') : null);
    const scrollOffsetRef = useRef(null); // ref for the scroll offset element, used when loading in pervious chat history

    // get the current active course name from the redux store
    const dispatch = useDispatch()
    const chatHistory = useSelector((state) => state.chatHistory.value)
    const messagesEndRef = useRef(null);

    // make our api calls for the messages
    const [ sendMessage, { isFetching: sendMessageIsFetching, currentData: sendMessageCurrentData }] = useLazySendMessageQuery();
    
    // api call to flag a response as a bad response
    const [ flagBadResponse ] = useLazyFlagBadResponseQuery();

    // Auth0 User Authentication and data
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    // click handler for flagging a response as a bad response
    const badReponseClickHandler = (chatHistoryId, index) => {
        // if the response is already flagged, return
        if(flaggedResponseIndexes.includes(index)){
            return;
        } else {
            // add the index to the flagged response indexes to prevent multiple flagging on front end
            setFlaggedResponseIndexes([...flaggedResponseIndexes, index]);
            // make the api call to flag the response
            flagBadResponse({chatHistoryId: chatHistoryId, badResponse: 1})
        }
    }

    // handle user input - when the user types a message and sends it
    const handleInput = (input) => {
        // append the user message to the chat history
        dispatch(appendUserMessage(input));
        handleScrollToBottom(); // scroll to bottom after user input
        // fetch a response from the server and append to chatHistory
        let timestamp = new Date().getTime();
        sendMessage({userQuestion: input, accessToken: accessToken ? accessToken : null, tokenId: tokenId.current ? tokenId.current : null, isAdmin: isAdmin, bustCache: timestamp, }, false);
    }

    // scroll to the bottom of the chat history
    const handleScrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "center"})
      };

      // get the access token from Auth0
    const handleGetToken = useCallback(async () => {
        const respToken = await getAccessTokenSilently({
            authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user",
            },
        });
        setAccessToken(respToken);
    }, [getAccessTokenSilently]);

    // if the user is not logged in or not trying to log and have not tried to log in, try logging them in
    useEffect(() => {
        async function checkUser() {
          if (isLoading || isAuthenticated || error === 'login_required') {
            return;
          } else {
            loginWithRedirect({
                authorizationParams: {
                  prompt: 'none'
                }
              });
          }
        }
        checkUser();
    }, [isLoading, isAuthenticated, error, loginWithRedirect]);

    // if they are authenticated get an access token
    useEffect(() => {
        if (isAuthenticated) {
            handleGetToken();
        }
    }, [isAuthenticated, handleGetToken]);
    
    // append the bot response to the chat history
    useEffect(() => {
        if(sendMessageCurrentData) {
            dispatch(appendBotResponse(sendMessageCurrentData));
            // if we didnt sent an access token from auth0 the back end will send us a tokenID to use for future requests
            // we store it as a cookie
            if(!tokenId.current && sendMessageCurrentData.tokenId){
                Cookies.set('tokenId', sendMessageCurrentData.tokenId, { expires: 1 })
                tokenId.current = sendMessageCurrentData.tokenId;
            }
        }
    }, [sendMessageCurrentData, tokenId, dispatch]);

    // scroll to the scroll offset when we load in previous chat history, or scroll to the bottom if we dont have a scroll offset
    useEffect(() => {
        if(scrollOffsetRef.current){
            scrollOffsetRef.current?.scrollIntoView({ behavior: "smooth", block: "center"})
        } else {
            handleScrollToBottom();
        }
    }
    , [chatHistory]);

    return (
        <div className="chat-container">
            <div className="chat-history">
                <div className="alert-container">
                    <AlertIcon />
                    <div>Kois Center Collective Intelligence is a prognostic tool that provides a response based on the information provided by the user. Please consult a medical professional for an accurate diagnosis.</div>
                </div>
                <LoadMoreBtn user={user} sendMessageIsFetching={sendMessageIsFetching} hasHistoryLoaded={hasHistoryLoaded} setHasHistoryLoaded={setHasHistoryLoaded} />
                {chatHistory.map((msg, i) => {
                    return (
                        <>
                            <> {hasHistoryLoaded && msg.welcomeMessage &&
                                <div className="loaded-messages-break-container">
                                    <div className="loaded-messages-break">Earlier Messages</div>
                                </div>}
                                <div key={msg.key} className={`${msg.source}-message-wrapper`} ref={msg.scrollOffset ? scrollOffsetRef : null}>
                                    {(msg.message && msg.source === 'bot') &&
                                    <BotMessageIcon />}
                                    <div>
                                        <div className={`${msg.source}-message ${isAuthenticated && msg.source === 'user' ? 'user-message--logged-in' : ''}` }>
                                            {msg.message && msg.source === 'bot' ? <div className={"bot-message__name"}>John</div> : ''}
                                            {msg.message && <div className={`${msg.source}-message__content`}><p>{msg.message}{<ContentTeaser gatedContent={msg.gatedContent}/>}{msg?.citations?.length ? '\nCitations:' : ''}{msg.citations ? msg.citations.map((c)=><Citation citation={c}/>) : '' }</p></div>}
                                        </div>
                                        {(msg.message && msg.source === 'bot' && !msg.welcomeMessage) &&
                                        // wire up the api call for a bad response
                                        <button className={"bad-response"} onClick={() => badReponseClickHandler(msg.chatHistoryId, i)}>
                                            <FlagIcon />
                                            <span className={flaggedResponseIndexes.includes(i) ? 'flagged': ''}>{flaggedResponseIndexes.includes(i) ? 'Flagged' : 'Flag as bad response'}</span>
                                        </button>}
                                    </div>
                                </div>
                            </>
                            <div ref={messagesEndRef} />
                        </>
                    );
                })}
                {sendMessageIsFetching && 
                    <BotFetching />
                }
            </div>
            <InputBar handleInput={handleInput} sendMessageIsFetching={sendMessageIsFetching} />
        </div>
    );
}

export default ChatCntr;